import Container from "Components/Container/Container";
// logo
import Logo from "./Components/Logo/Logo";
import Navigation from "./Components/Navigation/Navigation";
import MobileNavigation from "./Components/Navigation/MobileNavigation";
const Header = () => {
  return (
    <header className="py-4 lg:py-6">
      <Container>
        <div className="flex items-center justify-between">
          <Logo />
          <Navigation />
          <MobileNavigation />
        </div>
      </Container>
    </header>
  );
};

export default Header;
