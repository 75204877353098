import { PolicyContext } from "Context/PolicyContext";
import scrollToTop from "Helpers/ScrollToTop";
import { useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";
const Policy = () => {
  const { policyData, getPolicyData } = useContext(PolicyContext);
  const navigate = useNavigate();

  const handlePayment = async (route) => {
    getPolicyData(route);
  };

  useEffect(() => {
    if (policyData) {
      navigate(policyData.route);
      scrollToTop();
    }
  }, [policyData]);
  return (
    <div>
      <h6 className="font-medium text-xl mb-3">Legal</h6>
      <ul className="text-[#e6e6e6] flex flex-col gap-1.5">
        <button
          className="w-max"
          onClick={() => handlePayment("privacy-policy")}
        >
          Privacy Policy
        </button>
        <button
          className="w-max"
          onClick={() => handlePayment("cookie-policy")}
        >
          Cookie Policy
        </button>
        <button
          className="w-max"
          onClick={() => handlePayment("shipping-policy")}
        >
          Shipping Policy
        </button>
      </ul>
    </div>
  );
};

export default Policy;
