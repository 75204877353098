import { Link } from "react-router-dom";
import GetLinks from "Constant/Header/HeaderLinks";
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
};

const links = [
  {
    link: "Home",
    to: "/",
  },
  {
    link: "Category",
    to: "/",
    id: "category",
  },
  {
    link: "About",
    to: "/about-us/about-us",
  },
];

const LandingNav = () => {
  return (
    <div className="flex md:items-center md:justify-center mt-4">
      <nav className="">
        <ul className="flex flex-wrap ss:flex-nowrap md:items-center md:justify-center gap-y-2 gap-x-6 lg:gap-x-10">
          {links?.map(({ link, to, id }, index) => (
            <li key={index} className="font-medium lg:text-lg uppercase">
              <Link
                to={to}
                onClick={() => {
                  scrollToSection(id);
                }}
              >
                {" "}
                {link}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default LandingNav;
