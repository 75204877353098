import { useState } from "react";
import Container from "Components/Container/Container";
import ImageSkelton from "Components/UI/RequestHandler/ImageSkelton";

import rect from "Asset/images/About/reg.png";
const Hero = ({ data, title }) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  const formattedTitle = title
    .replace(/-/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());

  return (
    <section className="bg-lighGrey lg:h-[100vh]">
      <Container className={"h-full"}>
        <div className="flex flex-col lg:flex-row h-full lg:items-center relative ">
          <h2 className="text-5xl my-6 lg:hidden">
            {title === "about-us" ? "About Us" : formattedTitle}{" "}
          </h2>
          <div className="h-full lg:w-[60%] flex items-end">
            {imageIsLoading && <ImageSkelton />}
            <img
              src={data}
              alt=""
              onLoad={handleImageLoading}
              className={`w-full h-full object-cover ${
                imageIsLoading ? "hidden" : "block"
              }`}
            />
          </div>

          <div className="hidden lg:block lg:w-[40%] lg:relative">
            <img
              className="absolute top-1/2 -left-16 -translate-y-1/2"
              src={rect}
              alt=""
            />

            {title === "about-us" ? (
              <h1 className="text-[8rem] xl:text-[10rem] leading-[1] mx-auto w-max">
                About <br /> Us
              </h1>
            ) : (
              <h1 className="text-6xl xl:text-7xl xxl:text-8xl mb-20">
                {formattedTitle}
              </h1>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
