import React, { useState, useCallback, useEffect } from "react";
import IsError from "Components/UI/RequestHandler/IsError";
import IsLoading from "Components/UI/RequestHandler/IsLoading";
import Container from "Components/Container/Container";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation } from "swiper/modules";

// fetching data
import getData from "Helpers/GetContent";

const PrivateLabel = () => {
  const [labelData, setLabelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getLabelData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("labels");
      setLabelData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getLabelData();
  }, []);

  const data = labelData?.files;
  const isAnArray = data?.length > 1;

  return (
    <section className="h-[50vh] lg:h-[80vh]">
      <Container className="h-[50vh] lg:h-[80vh]">
        {isLoading && (
          <div className="flex items-center justify-center mt-10">
            {" "}
            <IsLoading />
          </div>
        )}
        {error && (
          <div className="flex items-center justify-center mt-10">
            {" "}
            <IsError />
          </div>
        )}
        {!isLoading && !error && isAnArray && (
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper w-full h-[50vh] lg:h-[80vh]"
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
          >
            {data?.map((image, index) => (
              <SwiperSlide key={index} className="w-full h-[50vh] lg:h-[80vh]">
                <div className="w-full h-[50vh] lg:h-[80vh]">
                  <img
                    src={image}
                    alt=""
                    className="w-full h-[50vh] lg:h-[80vh] select-none object-fit lg:object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {!isLoading && !error && !isAnArray && (
          <div className="h-full w-full">
            <img src={data} alt="" className="h-full w-full object-cover" />
          </div>
        )}
      </Container>
    </section>
  );
};

export default PrivateLabel;
