import Container from "Components/Container/Container";
import Box from "Components/UI/Box/Box";

import IsLoading from "Components/UI/RequestHandler/IsLoading";
import IsError from "Components/UI/RequestHandler/IsError";

const Boxes = ({ data, isLoading, error }) => {
  return (
    <section id="category" className="mt-secondary lg:mt-primary">
      <Container>
        {isLoading && (
          <div>
            <IsLoading />
          </div>
        )}
        {error && (
          <div>
            <IsError />
          </div>
        )}
        {!isLoading && !error && (
          <div className="special-grid relative ">
            {data?.map((cat, index) => (
              <Box key={index} data={cat} index={index} />
            ))}
          </div>
        )}
      </Container>
    </section>
  );
};

export default Boxes;
