import { SealWarning } from "@phosphor-icons/react";
const IsError = ({ iswhite }) => {
  return (
    <div className="flex items-center gap-x-4">
      <SealWarning size={32} weight="light" color="#d92128" />
      <p className={`font-cairo ${iswhite ? "text-white" : ""}`}>
        Unable to retrieve data. <br />
        Please try again later.
      </p>
    </div>
  );
};

export default IsError;
