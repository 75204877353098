import { LinkedinLogo } from "@phosphor-icons/react";

const Social = () => {
  return (
    <div className="flex md:items-center md:justify-center gap-x-4  lg:gap-x-6 mb-3">
      <a
        href="https://www.linkedin.com/in/kinetic-oco-792311299/"
        target="_blank"
        rel="noreferrer"
        className="bg-black flex items-center justify-center text-white rounded-full p-2  w-10 h-10"
      >
        <LinkedinLogo size={30} weight="light" />
      </a>
    </div>
  );
};

export default Social;
