import BASE_URL from "./Base_url";

const getHeaders = (language = "en") => {
  return {
    "Content-Type": "application/json",
    "Accept-Language": language,
  };
};

const getData = async (page, language) => {
  let url = `${BASE_URL}/${page}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(language),
    });

    if (!response.ok) {
      console.error(`Failed to perform GET . Status: ${response.status}`);
      throw new Error(`Failed to perform GET request }`);
    }

    return response.json();
  } catch (error) {
    console.error(`Failed to perform GET request for  Error: ${error.message}`);
    throw new Error(`Failed to perform GET request for`);
  }
};

export default getData;
