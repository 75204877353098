import React from "react";

const Label = ({ label }) => {
  return (
    <div className="flex items-center gap-x-5 bg-black py-2 w-[18rem] lg:w-[30rem] my-secondary rounded-[22px] -translate-x-[10%]">
      <div className="h-4 w-1/2 bg-gray-400"></div>
      <p className="text-white uppercase lg:text-xl text-center">{label}</p>
    </div>
  );
};

export default Label;
