import { Link } from "react-router-dom";
import logo from "Asset/images/logo/logo.png";

const Logo = () => {
  return (
    <div>
      <Link to={"/"}>
        <img className="w-52" src={logo} alt="" />
      </Link>
    </div>
  );
};

export default Logo;
