import React from "react";
import Container from "Components/Container/Container";
// UI
import IsLoading from "Components/UI/RequestHandler/IsLoading";
import IsError from "Components/UI/RequestHandler/IsError";
// css
import "./HeroStyle.css";
// helpers

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay } from "swiper/modules";

const Hero = ({ data, isLoading, error }) => {
  return (
    <section className="w-full h-[90vh] lg:h-[100vh]">
      {isLoading && (
        <div className="w-full h-[90vh] lg:h-[100vh] bg-gray-200 flex items-center justify-center">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="w-full h-[90vh] lg:h-[100vh] bg-gray-200 flex items-center justify-center">
          <IsError />
        </div>
      )}
      {!isLoading && !error && (
        <Swiper
          navigation={true}
          modules={[Navigation, Autoplay]}
          className="mySwiper w-full "
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={800}
        >
          {data.map(({ image, title }, index) => (
            <SwiperSlide
              key={index}
              className=" w-full h-[90vh] lg:h-[100vh] relative"
            >
              <div className="w-full h-full absolute top-0 left-0 z-[-1]">
                <img
                  src={image}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <Container>
                <div className="text-white h-[90vh] lg:h-[100vh] flex justify-center items-center lg:justify-start lg:items-start gap-y-10  lg:pt-44 xl:w-3/4">
                  <h1 className="italic text-4xl  sm:text-5xl md:text-6xl lg:text-7xl xxl:text-8xl leading-[1]">
                    {" "}
                    <span className="text-4xl ss:text-5xl lg:text-8xl">
                      "
                    </span>{" "}
                    {title}
                    <span className="text-4xl ss:text-5xl lg:text-8xl">"</span>
                  </h1>
                </div>
              </Container>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </section>
  );
};

export default Hero;
