import { useState } from "react";
import "./BoxStyle.css";
import { Link } from "react-router-dom";
import ImageSkelton from "Components/UI/RequestHandler/ImageSkelton";
import { CaretRight } from "@phosphor-icons/react";

const Box = ({ data: { image, title, subTitle, brand, about }, index }) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  return (
    <div className={`relative box box-${index + 1} group/image`}>
      <div className={`w-full h-full relative overflow-hidden`}>
        {about !== null && (
          <div className="absolute left-0 top-0 w-full h-full  backdrop-blur-lg group/link ">
            <Link
              to={`/about-us/${about}`}
              className={
                "w-full h-full block invisible group-hover/link:visible ease-in pl-2 lg:pl-4 text-white text-xs"
              }
            >
              <span className="flex items-center gap-x-1 absolute bottom-10    group-hover/link:bottom-1/3  transition-all duration-300">
                <p>Click to know more </p>
                <span className="animate-ping flex items-center pt-[1px]">
                  <CaretRight size={8} weight="bold" />
                </span>
              </span>
            </Link>
          </div>
        )}

        {brand !== null && (
          <div className="absolute left-0 top-0 w-full h-full group/brand ">
            <Link
              to={`/brand/${brand}`}
              className={
                "w-full h-full block invisible group-hover/brand:visible ease-in pl-2 lg:pl-4 text-white text-xs"
              }
            >
              <span className="flex items-center gap-x-1 absolute bottom-10  group-hover/brand:bottom-[40%]  transition-all duration-300">
                <p>Click to know more </p>
                <span className="animate-ping flex items-center pt-[1px]">
                  <CaretRight size={8} weight="bold" />
                </span>
              </span>
            </Link>
          </div>
        )}

        {image && (
          <img
            src={image}
            alt=""
            className={`w-full h-full object-cover ${
              about === null &&
              brand === null &&
              "group-hover/image:scale-[1.1] transition-all duration-500 ease-in"
            }`}
          />
        )}
      </div>

      <div className="absolute left-2 lg:left-4 top-1/2 -translate-y-1/2">
        <h2 className="text-white text-lg ss:text-xl xl:text-3xl">{title}</h2>
        {/* <p className="text-3xl relative z-[100]">{index + 1}</p> */}
      </div>
    </div>
  );
};

export default Box;
