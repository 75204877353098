import { Link } from "react-router-dom";
import Container from "Components/Container/Container";
import logo from "Asset/images/logo/logo.png";
import LandingNav from "./Components/LandingNav";
import NewsLetter from "./Components/NewsLetter";
import Social from "./Components/Social";
import scrollToTop from "Helpers/ScrollToTop";
const FooterLanding = () => {
  return (
    <section className="bg-lighGrey pt-secondary pb-secondary lg:pt-primary">
      <Container>
        <Link to={"/"} onClick={scrollToTop}>
          <div className="flex md:items-center md:justify-center w-3/4 lg:w-full md:mx-auto">
            <img src={logo} alt="" />
          </div>
        </Link>
        <LandingNav />
        <NewsLetter />
        <Social />
        <div className="flex flex-col md:items-center md:justify-center md:text-center gap-x-6">
          <p className="font-medium lg:text-lg">
            ALL rights are reserved Kinetic OCO SAS 2022{" "}
          </p>
          <p className="lg:text-lg font-medium">By Dow Group</p>
        </div>
      </Container>
    </section>
  );
};

export default FooterLanding;
