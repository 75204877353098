import Spinner from "./Spinner";

const IsLoading = (isWhite) => {
  return (
    <div>
      <Spinner isWhite={isWhite} />
      <p className={`font-cairo`}>
        Loading data, please wait. <br />
        This may take a moment.
      </p>
    </div>
  );
};

export default IsLoading;
