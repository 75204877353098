import scrollToTop from "Helpers/ScrollToTop";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// contextr
import { PolicyContext } from "Context/PolicyContext";
import { LanguageContext } from "Context/LanguageContext";
const ProductGuide = () => {
  const { policyData, getPolicyData } = useContext(PolicyContext);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handlePayment = async (route) => {
    getPolicyData(route, language);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (policyData) {
      navigate(policyData.route);
      scrollToTop();
    }
  }, [policyData]);

  return (
    <div>
      <h6 className="font-medium text-xl mb-3">Product Guide</h6>
      <ul className="text-[#e6e6e6] flex flex-col gap-1.5">
        <button className="w-max" onClick={() => handlePayment("size-guide")}>
          Size Guide
        </button>
        <button className="w-max" onClick={() => handlePayment("materials")}>
          Materials
        </button>
        <button className="w-max" onClick={() => handlePayment("product-care")}>
          Product Care
        </button>
      </ul>
    </div>
  );
};

export default ProductGuide;
