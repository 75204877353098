import React, { useEffect, useContext, useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import getData from "Helpers/GetContent";

import Banner from "./sections/Banner";
import AboutBrand from "./sections/AboutBrand";

import IsError from "Components/UI/RequestHandler/IsError";
import IsLoading from "Components/UI/RequestHandler/IsLoading";
import { LanguageContext } from "Context/LanguageContext";

const Brand = () => {
  const { slug } = useParams();
  const { language } = useContext(LanguageContext);
  const [brandData, setBrandData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getBrandData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(`brand/${slug}`, language);
      setBrandData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [language, slug]);

  useEffect(() => {
    getBrandData(language);
  }, [slug, language, getBrandData]);

  const bannerImage = brandData?.brand?.banner;
  const data = brandData?.brand?.content || {};
  const gallery = brandData?.brand?.gallery || [];

  return (
    <main>
      {isLoading && (
        <div className="flex items-center justify-center mt-20">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="flex flex-col gap-4 items-center justify-center mt-20">
          <IsError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <Banner image={bannerImage} />
          <AboutBrand
            data={data}
            gallery={gallery}
            isLoading={isLoading}
            error={error}
          />
        </>
      )}
    </main>
  );
};

export default Brand;
