import { useContext } from "react";
import { HomeContext } from "Context/HomeContext";

const GetLinks = () => {
  const contextValue = useContext(HomeContext);

  const updatedNavLinks = [
    {
      link: "Home",
      to: "",
    },
    {
      link: "About us",
      to: "/about-us/about-us",
    },
    {
      link: "category",
      to: "",
      id: "category",
    },
    {
      link: "Brands",
      megaNav: true,
      isLoading: contextValue?.isLoading,
      isError: contextValue?.error,
      pages: contextValue?.categoryData,
    },
  ];

  return updatedNavLinks;
};

export default GetLinks;
