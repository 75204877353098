import { useState } from "react";

import ImageSkelton from "Components/UI/RequestHandler/ImageSkelton";

const Banner = ({ image }) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  return (
    <section>
      <div className="w-full h-[300px] max-h-[300px] ">
        {imageIsLoading && <ImageSkelton />}
        <img
          src={image}
          onLoad={handleImageLoading}
          alt="brand-banner"
          className={`w-full h-full object-cover ${
            imageIsLoading ? "hidden" : "block"
          }`}
        />
      </div>
    </section>
  );
};

export default Banner;
