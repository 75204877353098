import { useState } from "react";
import Container from "Components/Container/Container";
import "./Brand.css";
import ImageSkelton from "Components/UI/RequestHandler/ImageSkelton";
const AboutBrand = ({ data, gallery, isLoading, error }) => {
  const { text, image, buttons } = data;
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [galleryImageIsLoading, setGalleryImageIsLoading] = useState(true);
  const handleImageLoading = () => {
    setImageIsLoading(false);
  };

  const hanldeGalleryImageLoading = () => {
    setGalleryImageIsLoading(false);
  };

  return (
    <section className="mt-secondary">
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-5 xl:items-center">
          <div className="lg:col-span-3">
            <span className="flex flex-col  gap-6 lg:w-3/4">
              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </span>
            <div className="flex flex-wrap items-center gap-4 mt-10 ">
              {buttons?.map(({ text, link }, index) => (
                <button
                  key={index}
                  className="border border-black rounded-[22px] px-8 py-2 transition ease-in duration-300 hover:bg-black hover:text-white"
                >
                  <a href={link} target="_blank" rel="noreferrer">
                    {text}
                  </a>
                </button>
              ))}
            </div>
          </div>

          <div className="lg:col-span-2 w-full h-full mt-10 lg:mt-0 lg:h-[600px]">
            {imageIsLoading && <ImageSkelton />}
            <img
              onLoad={handleImageLoading}
              className={`w-full max-h-full object-cover ${
                imageIsLoading ? "hidden" : "block"
              }`}
              src={image}
              alt=""
            />
          </div>
        </div>

        <div className="grid-container justifty-between mt-secondary">
          {gallery?.map((img, index) => (
            <div key={index} className="min-w-full h-full ">
              {galleryImageIsLoading && <ImageSkelton />}
              <img
                onLoad={hanldeGalleryImageLoading}
                src={img}
                alt=""
                className={`min-w-full h-full object-cover ${
                  galleryImageIsLoading ? "hidden" : "block"
                }`}
              />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AboutBrand;
