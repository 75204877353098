import React, { useEffect, useContext } from "react";

// home components
import Hero from "./Sections/Hero/Hero";
import Boxes from "./Sections/Boxes/Boxes";

// fetching data
import { HomeContext } from "Context/HomeContext";
import { LanguageContext } from "Context/LanguageContext";

const Home = () => {
  const { getHomeData, homeData, isLoading, error } = useContext(HomeContext);
  const { language } = useContext(LanguageContext);

  const heroData = homeData?.hero || [];
  const categoryData = homeData?.category || [];

  useEffect(() => {
    getHomeData(language);
  }, [language]);

  return (
    <main>
      <Hero data={heroData} isLoading={isLoading} error={error} />
      <Boxes data={categoryData} isLoading={isLoading} error={error} />
    </main>
  );
};

export default Home;
