import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { CaretDown } from "@phosphor-icons/react";
import { LanguageContext } from "Context/LanguageContext";
import GetLinks from "Constant/Header/HeaderLinks";
import scrollToSection from "Helpers/ScrollToSection";

const Navigation = () => {
  const navLinks = GetLinks();

  const [isHovered, setIsHovered] = useState(null);
  const { language, changeLanguageHandler } = useContext(LanguageContext);

  const handleMouseEnter = (buttonId) => {
    setIsHovered(buttonId);

    if (buttonId === null) {
      setIsHovered(null);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  return (
    <div className="hidden lg:block">
      <nav className="">
        <ul className="flex items-center gap-x-6">
          {navLinks?.map(({ link, to, id, megaNav, pages, isError }, index) => (
            <li
              onMouseEnter={() => handleMouseEnter(index)}
              key={index}
              className="uppercase font-medium relative "
            >
              <Link
                className="flex items-center gap-x-[2px]"
                onClick={() => scrollToSection(id)}
                to={to}
              >
                {" "}
                {megaNav && <CaretDown size={20} weight="light" />}
                {link}
              </Link>

              {megaNav && (
                <div
                  onMouseLeave={handleMouseLeave}
                  className={`absolute z-[1000] w-36 bg-white border border-black flex flex-col mt-2 z-[100000] ${
                    isHovered === index
                      ? "opacity-100 visible translate-y-[0%] fixed"
                      : "opacity-0 invisible translate-y-[20%] "
                  }`}
                >
                  {isError && (
                    <p className="w-full text-sm text-start p-3  transition ease-in duration-300 hover:bg-black hover:text-white ">
                      Failed to get data..
                    </p>
                  )}
                  {!isError &&
                    pages?.map(
                      ({ brand }, index) =>
                        brand !== null && (
                          <Link
                            to={`brand/${brand}`}
                            className="{w-full text-sm text-start p-3  transition ease-in duration-300 hover:bg-black hover:text-white z-[1000] } "
                            key={index}
                          >
                            {brand}
                          </Link>
                        )
                    )}
                </div>
              )}
            </li>
          ))}

          {/* <div className="relative">
            <button
              onMouseEnter={() => handleMouseEnter("language")}
              className="uppercase font-medium flex items-center gap-x-[2px]"
            >
              <CaretDown size={20} weight="light" />
              {language === "en" ? "English" : "French"}
            </button>

            <div
              onMouseLeave={handleMouseLeave}
              className={`absolute z-[1000] w-24 bg-white flex flex-col items-start  border border-black mt-2  ${
                isHovered === "language"
                  ? "opacity-100 visible translate-y-[0%] fixed"
                  : "opacity-0 invisible translate-y-[20%] "
              }`}
            >
              <button
                onClick={() => changeLanguageHandler("en")}
                className="capitalize text-start p-3  w-full transition-ease-in duration-300 hover:bg-black hover:text-white"
              >
                EN
              </button>
              <button
                onClick={() => changeLanguageHandler("fr")}
                className="capitalize text-start p-3 w-full transition-ease-in duration-300 hover:bg-black hover:text-white"
              >
                FR
              </button>
            </div>
          </div> */}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
