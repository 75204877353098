import { LanguageProvider } from "./LanguageContext";
import { HomeProvider } from "./HomeContext";
import { PolicyProvider } from "./PolicyContext";

const ContextProvider = ({ children }) => {
  return (
    <LanguageProvider>
      <HomeProvider>
        <PolicyProvider>{children}</PolicyProvider>
      </HomeProvider>
    </LanguageProvider>
  );
};

export default ContextProvider;
