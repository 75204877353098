import { Routes, Route } from "react-router-dom";
// layout
import Header from "Layout/Header/Header";
import Footer from "Layout/Footer/Footer";

// pages
import Home from "Pages/Home/Home";
import About from "Pages/About/About";
import Brand from "Pages/Brand/Brand";
// policy
import ShipingPolicy from "Pages/Policy/ShipingPolicy";
import Cookie from "Pages/Policy/Cookie";
import Materials from "Pages/Policy/Materials";
import PrivaryPolicy from "Pages/Policy/PrivaryPolicy";
import ProductCare from "Pages/Policy/ProductCare";
import SizeQuide from "Pages/Policy/SizeQuide";

// context
import ContextProvider from "Context/ContextProvider";

import ScrollToTop from "Helpers/ScrollToTop";

const App = () => {
  return (
    <div className="App">
      <ContextProvider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route index element={<Home />} />
          <Route path="about-us/:slug" element={<About />} />
          <Route path="brand/:slug" element={<Brand />} />
          {/* policy */}
          <Route path="shipping-policy" element={<ShipingPolicy />} />
          <Route path="cookie-policy" element={<Cookie />} />
          <Route path="materials" element={<Materials />} />
          <Route path="privacy-policy" element={<PrivaryPolicy />} />
          <Route path="product-care" element={<ProductCare />} />
          <Route path="size-guide" element={<SizeQuide />} />
        </Routes>

        <Footer />
      </ContextProvider>
    </div>
  );
};

export default App;
