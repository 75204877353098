import { MapPin, Phone, EnvelopeSimple } from "@phosphor-icons/react";
import React, { useEffect, useState, useCallback } from "react";
import getData from "Helpers/GetContent";
import Spinner from "Components/UI/RequestHandler/Spinner";
import IsError from "Components/UI/RequestHandler/IsError";
const ContactUs = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getContactInfo = useCallback(async (language) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData("contact-info", language);
      setContactInfo(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getContactInfo();
  }, [getContactInfo]);

  let phoneNumberWithoutSpaces = contactInfo?.infoData?.phone?.replace(
    /\s/g,
    ""
  );
  return (
    <div>
      <h6 className="font-medium text-xl mb-3">Contact us</h6>
      {isLoading && <Spinner />}
      {error && <IsError />}
      {!isLoading && !error && (
        <ul className="text-[#e6e6e6] flex flex-col gap-1.5">
          <li className="flex items-center gap-x-2">
            <MapPin size={20} weight="light" />
            <p>{contactInfo?.infoData?.address}</p>
          </li>
          <li className="flex items-center gap-x-2">
            <Phone size={20} weight="light" />
            <a href={`tel:${phoneNumberWithoutSpaces}`}>
              {contactInfo?.infoData?.phone}
            </a>
          </li>
          <li className="flex items-center gap-x-2">
            <EnvelopeSimple size={20} weight="light" />
            <a href={`mailto:${contactInfo?.infoData?.email}`}>
              {contactInfo?.infoData?.email}
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default ContactUs;
