import { useContext, useEffect } from "react";
import { PolicyContext } from "Context/PolicyContext";
import IsError from "Components/UI/RequestHandler/IsError";
import IsLoading from "Components/UI/RequestHandler/IsLoading";
import Container from "Components/Container/Container";
import { LanguageContext } from "Context/LanguageContext";

const ShippingPolicy = () => {
  const { isLoading, error, policyData, getPolicyData } =
    useContext(PolicyContext);
  const { language } = useContext(LanguageContext);
  useEffect(() => {
    getPolicyData(policyData.route, language);
  }, [language]);

  const formatRoute = (route) => {
    return route
      ?.split("-")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join("  ");
  };

  return (
    <main>
      {isLoading && (
        <div className="flex items-center justify-center mt-20">
          <IsLoading />
        </div>
      )}
      {error && (
        <div className="flex items-center justify-center mt-20">
          <IsError />
        </div>
      )}
      {!isLoading && !error && (
        <>
          <div className="bg-lighGrey">
            <Container>
              <div className="flex items-center justify-center py-16">
                <h1 className="text-5xl font-semibold">
                  {formatRoute(policyData?.route)}
                </h1>
              </div>
            </Container>
          </div>

          <Container>
            <div className="mt-10">
              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{ __html: policyData?.data }}
              />
            </div>
          </Container>
        </>
      )}
    </main>
  );
};

export default ShippingPolicy;
