import React, { useState } from "react";
import BASE_URL from "Helpers/Base_url";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDone, setIsDone] = useState(false);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);

    // Check if the input is a valid email address using a simple regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputValue));
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    setIsDone(false);
    if (!isValidEmail) {
      setErrorMessage("Please enter a valid email");
      return;
    }

    try {
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("email", email);

      const response = await fetch(`${BASE_URL}/subscribe`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setIsDone(true);
        setEmail("");
      } else {
        // Handle error response, if needed
        setErrorMessage("Failed to subscribe. Please try again later.");
      }
    } catch (error) {
      // Handle network or other errors
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col md:items-center md:justify-center md:w-3/4 md:mx-auto lg:w-full my-10 lg:my-20">
      <div className="text-center mb-4">
        <h6 className="font-bold text-xl lg:text-2xl">JOIN OUR NEWSLETTER</h6>
        <p className="text-xl lg:text-2xl">Stay updated with our latest news</p>
      </div>

      {errorMessage && (
        <p className="text-red-400 text-center mb-3 ">{errorMessage}</p>
      )}
      {isDone && (
        <p className="text-green-600 text-center mb-3 ">
          Welcome, thank you for joining
        </p>
      )}

      <div className="flex flex-col w-full md:items-center gap-1.5 md:justify-center">
        <input
          className={`bg-white w-full lg:w-[24rem] px-4 py-2 rounded-2xl ${
            isValidEmail ? "" : "border-red-500"
          }`}
          type="email"
          placeholder="Your email address..."
          value={email}
          onChange={handleChange}
        />
        <button
          onClick={handleSubmit}
          className="rounded-2xl bg-blue-600 text-white px-8 py-2"
          disabled={isSubmitting}
        >
          {isSubmitting ? "SUBSCRIBING..." : "SUBSCRIBE"}
        </button>
      </div>
    </div>
  );
};

export default NewsLetter;
