import React, { useState, useCallback, useEffect, useContext } from "react";
import IsError from "Components/UI/RequestHandler/IsError";
import IsLoading from "Components/UI/RequestHandler/IsLoading";
// components
import Hero from "./Section/Hero/Hero";
import AboutContent from "./Section/About/AboutContent";

// fetching data
import getData from "Helpers/GetContent";
import { LanguageContext } from "Context/LanguageContext";

import { useParams } from "react-router-dom";

const About = () => {
  const [aboutData, setAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useContext(LanguageContext);
  const { slug } = useParams();

  const getAboutData = useCallback(async () => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(`about-us/${slug}`, language);
      setAboutData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [language, slug]);

  useEffect(() => {
    getAboutData();
  }, [language, slug, getAboutData]);

  const hero = aboutData?.about[0]?.hero;
  const content = aboutData?.about[0]?.content;

  const pageTitle = aboutData?.about[0].content.slug;

  return (
    <main>
      {isLoading && (
        <div className="mt-14 flex items-center justify-center">
          <IsLoading />
        </div>
      )}

      {error && (
        <div className="mt-14 flex items-center justify-center">
          <IsError />
        </div>
      )}

      {!isLoading && !error && (
        <>
          <Hero title={pageTitle} data={hero} />
          <AboutContent data={content} />
        </>
      )}
    </main>
  );
};

export default About;
