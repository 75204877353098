import React from "react";

const Aboutus = () => {
  return (
    <div className="lg:col-span-2 lg:w-3/4 xl:w-full">
      <h6 className="font-medium text-xl mb-3">About Us</h6>
      <p className="pr-10 text-[#e6e6e6]">
        With a long trail of experience in the garments manufacturing industry
        since 1971, KARIS has come to create thoughtful pieces of loungewear,
        sleepwear, linen and matching accessories.
      </p>
    </div>
  );
};

export default Aboutus;
