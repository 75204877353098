import { List, X } from "@phosphor-icons/react";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./Style.css";
import GetLinks from "Constant/Header/HeaderLinks";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { LanguageContext } from "Context/LanguageContext";
import Spinner from "Components/UI/RequestHandler/Spinner";

const MobileNavigation = () => {
  const [navIsActive, setNavIsActive] = useState(false);
  const [subMenuIsActvie, setSubMenuIsActive] = useState(false);
  const [languageMenuIsActive, SetLanguageMenuIsActive] = useState(false);
  const { language, changeLanguageHandler } = useContext(LanguageContext);

  const handleNav = () => {
    setNavIsActive((cur) => !cur);
  };

  const handleSubMenu = () => {
    setSubMenuIsActive((cur) => !cur);
  };

  const handleLanguageMenu = () => {
    SetLanguageMenuIsActive((cur) => !cur);
  };

  useEffect(() => {
    const body = document.body;

    // Add or remove the 'overflow-hidden' class based on the navIsActive state
    if (navIsActive) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }

    // Cleanup: Remove the 'overflow-hidden' class when the component unmounts
    return () => {
      body.classList.remove("overflow-hidden");
    };
  }, [navIsActive]);

  const navLinks = GetLinks();
  return (
    <div className="lg:hidden">
      <button className="relative z-[10000]" onClick={handleNav}>
        {!navIsActive && <List size={32} weight="light" />}
        {navIsActive && (
          <span className="text-white">
            <X size={32} weight="light" />
          </span>
        )}
      </button>

      <div
        className={`absolute w-[100vw] h-[100vh] bg-black left-0 top-0 z-[100] transition ease-in duration-500  ${
          navIsActive
            ? "translate-y-[0] translate-x-[0%] scale-[1] opacity-100 select-auto visible"
            : "-translate-y-[100%] -translate-x-[100%] scale-[0] opacity-0 select-none invisible"
        }`}
      >
        <nav className=" flex mt-44 px-4 h-full">
          <ul className="flex flex-col w-full gap-6 text-white">
            {navLinks?.map(
              ({ link, to, megaNav, pages, isError, isLoading }, index) => (
                <li
                  key={index}
                  className={`text-xl uppercase font-medium transition ease-in duration-700  ${
                    navIsActive
                      ? "scale-[1] translate-y-[0%] opacity-100"
                      : "scale-[0]  -translate-y-[100%] opacity-0"
                  }`}
                >
                  {megaNav ? (
                    <div
                      onClick={handleSubMenu}
                      className={`relative flex  gap-x-2  flex-col ${
                        subMenuIsActvie ? "" : "border-b border-[#ccc]"
                      }`}
                    >
                      <span className="flex items-center gap-x-2">
                        <p className="text-xl">{link}</p>
                        {subMenuIsActvie ? (
                          <CaretUp size={24} />
                        ) : (
                          <CaretDown size={24} />
                        )}
                      </span>

                      <div
                        className={`w-36 flex flex-col  gap-3 my-5 z-[100000] transition ease-in duration-100 ${
                          subMenuIsActvie
                            ? "opacity-100 relative select-auto"
                            : "opacity- absolute left-0 "
                        } `}
                      >
                        {isError && (
                          <p className="w-full text-sm text-start p-3  transition ease-in duration-300 hover:bg-black hover:text-white ">
                            Failed to get data..
                          </p>
                        )}

                        {isLoading && (
                          <div
                            className={`${
                              subMenuIsActvie
                                ? "visible	select-auto"
                                : "select-none invisible"
                            }`}
                          >
                            <Spinner />{" "}
                          </div>
                        )}
                        {!isError &&
                          pages?.map(
                            ({ brand }, index) =>
                              brand !== null && (
                                <Link
                                  onClick={() => {
                                    handleSubMenu();
                                    handleNav();
                                  }}
                                  to={`brand/${brand}`}
                                  className={`w-full text-md text-[#bbb] z-[1000] block ${
                                    subMenuIsActvie
                                      ? "visible	select-auto"
                                      : "select-none invisible"
                                  }`}
                                  key={index}
                                >
                                  {brand}
                                </Link>
                              )
                          )}
                      </div>
                    </div>
                  ) : (
                    <Link
                      className="flex items-center gap-x-2 border-b border-[#ccc]"
                      to={to}
                      onClick={handleNav}
                    >
                      {" "}
                      {link}
                    </Link>
                  )}
                </li>
              )
            )}

            {/* languages */}
            {/* <div
              className={`relative transition ease-in duration-700 ${
                navIsActive
                  ? "scale-[1] translate-y-[0%] opacity-100"
                  : "scale-[0]  -translate-y-[100%] opacity-0"
              }`}
            >
              <button
                onClick={handleLanguageMenu}
                className="border-b border-[#ccc] flex items-center gap-x-2 text-xl w-full"
              >
                {language === "en" ? "English" : "French"}
                {languageMenuIsActive ? (
                  <CaretUp size={24} />
                ) : (
                  <CaretDown size={24} />
                )}
              </button>

              <div
                className={`absolute w-36 flex flex-col gap-3 mt-4 z-[100000]  items-start justify-start transition ease-in duration-300 ${
                  languageMenuIsActive ? "opacity-100" : "opacity-0"
                }`}
              >
                <button
                  onClick={() => {
                    changeLanguageHandler("en");
                    handleNav();
                    handleLanguageMenu();
                  }}
                  className="w-full text-md text-start text-[#bbb] z-[1000]"
                >
                  English
                </button>
                <button
                  onClick={() => {
                    changeLanguageHandler("fr");
                    handleNav();
                    handleLanguageMenu();
                  }}
                  className="text-start text-md text-[#ccc] z-[1000]  "
                >
                  French
                </button>
              </div>
            </div> */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileNavigation;
