import Container from "Components/Container/Container";

const AboutContent = ({ data }) => {
  return (
    <section className="my-seondar lg:my-primary">
      <Container>
        <div className="flex flex-col lg:flex-row ">
          <div className="lg:flex-1 flex flex-col justify-center gap-y-6 border-b-[6px] lg:border-b-0 lg:border-r-[6px] border-black py-14 lg:px-8 xl:px-10 ">
            <p
              className="font-cairo"
              dangerouslySetInnerHTML={{ __html: data?.about }}
            />
          </div>
          <div className="lg:flex-1 flex flex-col justify-center  gap-y-6 py-14 lg:px-8 xl:px-10">
            <span>
              <h6 className="text-xl font-medium uppercase">
                {data?.values?.first_title}
              </h6>
              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{ __html: data?.values?.first_text }}
              />
            </span>
            <span>
              <h6 className="text-xl font-medium uppercase">
                {data?.values?.second_title}
              </h6>
              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{ __html: data?.values?.second_text }}
              />
            </span>
            <span>
              <h6 className="text-xl font-medium uppercase">
                {data?.values?.third_title}
              </h6>
              <p
                className="font-cairo"
                dangerouslySetInnerHTML={{ __html: data?.values?.third_text }}
              />
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutContent;
