import React, { createContext, useState } from "react";
import getData from "Helpers/GetContent";

export const PolicyContext = createContext();

export const PolicyProvider = ({ children }) => {
  const [policyData, setPolicyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getPolicyData = async (route, language) => {
    try {
      setError(false);
      setIsLoading(true);
      const data = await getData(route, language);
      if (data.data === null) {
        return;
      } else {
        setPolicyData({
          route,
          ...data,
        });
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PolicyContext.Provider
      value={{ isLoading, error, getPolicyData, policyData }}
    >
      {children}
    </PolicyContext.Provider>
  );
};
