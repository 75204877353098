import PrivateLabel from "./components/PrivateLabel/PrivateLabel";
import Label from "Components/UI/Label/Label";
import FooterBox from "./components/FooterBanner/FooterBox";
import FooterLanding from "./components/FooterLanding/FooterLanding";

const Footer = () => {
  return (
    <footer>
      <Label label={"private label"} />
      <PrivateLabel />
      <Label label={"lower banner"} />
      <FooterBox />
      <FooterLanding />
    </footer>
  );
};

export default Footer;
