import React from "react";
import Container from "Components/Container/Container";

// components
import Aboutus from "./components/Aboutus";
import ProductGuide from "./components/ProductGuide";
import Policy from "./components/Policy";
import ContactUs from "./components/ContactUs";

const FooterBox = () => {
  return (
    <footer className="bg-grey py-secondary lg:py-primary">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-5 gap-8 lg:gap-14 text-white">
          <Aboutus />
          <ProductGuide />
          <Policy />
          <ContactUs />
        </div>
      </Container>
    </footer>
  );
};

export default FooterBox;
